import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faTable, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
 
const FilterSection = ({ startDate, setStartDate, handlePreviousDay, handleNextDay, searchTerm, setSearchTerm, view, setView, handleApiResponse, setLoading, isValidIP, setIsValidIP, initialDate, initialPrefix }) => {
  const [response, setResponse] = useState(null);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
 
  const handleGoBack = () => {
    navigate(-1);
  };
 
  const handleIPChange = (e) => {
    const ip = e.target.value;
    setSearchTerm(ip);
 
    const isValidIPv4 = (ip) => {
      const [address, prefix] = ip.split('/');
      const segments = address.split('.');
      if (segments.length === 4 && (!prefix || (Number(prefix) >= 0 && Number(prefix) <= 32))) {
        return segments.every(segment => /^\d+$/.test(segment) && Number(segment) >= 0 && Number(segment) <= 255 &&
          (segment === "0" || !segment.startsWith("0")));
      }
      return false;
    };
 
    const isValidIPv6 = (ip) => {
      const [address, prefix] = ip.split('/');
      const segments = address.split(':');
      if (segments.length <= 8 && (!prefix || (Number(prefix) >= 0 && Number(prefix) <= 128))) {
        return segments.every(segment => segment === "" || /^[0-9a-fA-F]{1,4}$/.test(segment));
      }
      return false;
    };
 
    setIsValidIP(ip.includes('.') ? isValidIPv4(ip) : ip.includes(':') && isValidIPv6(ip));
  };
 
  useEffect(() => {
    const fetchData = (date, prefix) => {
      setLoading(true);
      var formattedDate = startDate.toISOString().split("T")[0];
      let apiUrl = "";
      if (searchTerm.includes("/")) {
        if (searchTerm.includes(":")) {
          apiUrl = `https://api.manycast.net/ipv6/prefix/${formattedDate}?prefix=${searchTerm}`;
        } else {
          apiUrl = `https://api.manycast.net/ipv4/prefix/${formattedDate}?prefix=${searchTerm}`;
        }
      } else if (!searchTerm.includes("?")) {
        if (searchTerm.includes(":")) {
          apiUrl = `https://api.manycast.net/ipv6/${formattedDate}?prefix=${searchTerm}`;
        } else {
          apiUrl = `https://api.manycast.net/ipv4/${formattedDate}?prefix=${searchTerm}`;
        }
      }
      fetch(apiUrl)
        .then((res) => {
          if (!res.ok) {
            throw new Error("Data not found in the database");
          }
          return res.json();
        })
        .then((data) => {
          setResponse(data);  
          handleApiResponse(data);
          setError(null);
        })
        .catch((error) => {
          console.error("Error fetching the API:", error);
          setError(error.message);
          handleApiResponse({
            prefix: "",
            count: 0,
            characterization: {},
            instances: []
        })    
      })
        .finally(() => {
          setLoading(false); 
        });
    };

    const isValidIPv4 = (ip) => {
      const [address, prefix] = ip.split('/');
      const segments = address.split('.');
      return (
        segments.length === 4 &&
        segments.every((seg) => /^\d+$/.test(seg) && parseInt(seg, 10) >= 0 && parseInt(seg, 10) <= 255) &&
        (!prefix || (parseInt(prefix, 10) >= 0 && parseInt(prefix, 10) <= 32))
      );
    };
  
    const isValidIPv6 = (ip) => {
      const [address, prefix] = ip.split('/');
      const segments = address.split(':');
      return (
        segments.length <= 8 &&
        segments.every((seg) => seg === '' || /^[0-9a-fA-F]{1,4}$/.test(seg)) &&
        (!prefix || (parseInt(prefix, 10) >= 0 && parseInt(prefix, 10) <= 128))
      );
    };
  
    const isValid = searchTerm.includes('.') ? isValidIPv4(searchTerm) : searchTerm.includes(':') && isValidIPv6(searchTerm);
    setIsValidIP(isValid);
    fetchData(startDate, searchTerm);
  }, [searchTerm, startDate]); 
  
  
  const formatDate = (date) => date ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}` : null;
 
  return (
    <div className="filter-section">
      <div className="filter-item">
        <label>Select Date:</label>
        <DatePicker selected={startDate} onChange={date => setStartDate(date)} onKeyDown={(e) => e.preventDefault()} />
      </div>
      <div className="filter-item">
        <button onClick={() => {
              const previousDay = new Date(startDate);
              previousDay.setDate(startDate.getDate() - 1);
              setStartDate(previousDay);
              }}>Previous Day</button>
        <button onClick={() => {
              const nextDay = new Date(startDate);
              nextDay.setDate(startDate.getDate() + 1);
              setStartDate(nextDay); 
              }}>Next Day</button>
      </div>
      <div className="filter-item search-wrapper">
        <label>Enter Prefix:</label>
        <input type="text" placeholder="Prefix" onChange={handleIPChange} value={searchTerm} />
      </div>
      <div className="toggle-container">
        <label className={`toggle-switch ${view === "table" ? "on" : ""}`}>
          <input type="checkbox" checked={view === "table"} onChange={() => setView(view === "map" ? "table" : "map")} />
          <span className="toggle-slider"></span>
          <FontAwesomeIcon icon={faMap} className="toggle-icon map-icon" />
          <FontAwesomeIcon icon={faTable} className="toggle-icon table-icon" />
        </label>
       
      </div>
      <div className={isValidIP ? "valid-ip" : "invalid-ip"}>{isValidIP ? "✅ Prefix entry is valid" : "❌ Prefix entry is not valid"}</div>
        <button onClick={handleGoBack} className="go-back-button">⬅️ Stats</button>
        {error && <div className="error-message">{'⚠️' + error}</div>}
    </div>
  );
};
 
export default FilterSection;
